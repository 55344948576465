import { CSSProperties, memo } from 'react';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import Tooltip from '../Tooltip';

type TabButtonProps = {
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  borderless?: boolean;
  style?: CSSProperties;
};

const TabButton: FCWithChildren<TabButtonProps> = (props) => {
  const { selected = false, onClick, className, children, borderless, style } = props;

  const borderColor = selected ? `border-primary-2` : (borderless ? 'border-transparent' : 'border-gray-5') + ' hover:border-primary-2';

  const textColor = selected ? `text-color-1 font-medium` : `text-color-2`;

  return (
    <div
      {...dataAttributeProps(props)}
      className={`flex justify-center ${className} min-w-0 cursor-pointer select-none items-center border-b-2 px-2 py-3 transition-colors duration-150 ease-in-out ${borderColor} ${textColor}`}
      onClick={() => onClick && onClick()}
      data-testid="tab-strip-tab"
      style={style}
    >
      <Tooltip text={children} truncatedTextMode>
        {(tooltip) => (
          <div {...tooltip} className="flex-1 truncate text-center">
            {children}
          </div>
        )}
      </Tooltip>
    </div>
  );
};

export default memo(TabButton);
