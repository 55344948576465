/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Children,
  FC,
  ReactElement,
  ReactNode,
  Suspense,
  createContext,
  memo,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSlot from '../../../hooks/useSlots';
import { FCWithChildren } from '../../../types/FCWithChildren';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import withSlot, { SlotDefinitions, SlotType } from '../../../wrappers/withSlot';
import TopNavPortal from '../../layout/top-menu/TopNavPortal';
import { Option } from '../../Option';
import CheckIcon from '../icon/CheckIcon';
import PageLoader from '../page-loader/PageLoader';
import TabButton from './TabButton';
import { useAccordionContext } from '../accordion/Accordion';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';

type TabHeaderInternalState = {
  selected: boolean;
  onClick: (id: string) => void;
};

type FCSlots<T> = T extends { Slot: SlotType<infer S> } ? { _slots: S } : never;
type FCProps<T> = T extends FC<infer P> ? P : never;
type TabHeaderInternalProps = FCProps<typeof TabHeader> &
  FCSlots<typeof TabHeader> & {
    internalState: TabHeaderInternalState;
    evenTabs?: boolean;
    borderless?: boolean;
    allowWrapping?: boolean;
    bgColor?: string;
    overflowTabs?: boolean;
    isBottom?: boolean;
    fillSelected?: boolean;
  };

const TabHeaderContext = createContext({ selected: false });

export const useTabHeader = () => useContext(TabHeaderContext);

const TabHeaderInternal: FC<TabHeaderInternalProps> = memo(function TabStripHeaderInternal(props) {
  const { id, internalState, evenTabs, text, value, borderless, allowWrapping, bgColor = '', overflowTabs, isBottom, fillSelected } = props;

  const leading = useSlot(props, 'Leading');
  const trailing = useSlot(props, 'Trailing');

  return (
    <TabButton
      data-cy={`tab-header-${id}`}
      {...dataAttributeProps(props)}
      key={`tab-${id}`}
      className={`${internalState.selected ? 'selected' : ''} ${allowWrapping ? 'm-[3px]' : ''} ${bgColor} ${fillSelected ? 'gradient-border border-r-2 !py-2' : ''} ${fillSelected && !allowWrapping ? '-mb-[2px]' : ''} ${evenTabs ? 'flex-1' : ''} ${overflowTabs ? 'min-w-fit' : ''}`}
      onClick={() => internalState.onClick(id)}
      selected={internalState.selected}
      borderless={borderless}
      style={{ padding: allowWrapping && borderless ? 'calc(0.75rem - 5px)' : '' }}
    >
      <TabHeaderContext.Provider value={{ selected: internalState.selected }}>
        <div className="relative flex items-center justify-center gap-2">
          {typeof value === 'boolean' && (
            <div
              className={`rounded-full ${
                value ? 'bg-semantic-1' : 'border-semantic-1 border-2 bg-white'
              } mr-2 flex h-4 w-4 items-center justify-center text-white`}
            >
              <CheckIcon className={`${value ? '' : 'invisible'} h-3 w-3`} />
            </div>
          )}
          <span>{leading()}</span>
          <span className="truncate">{text}</span>
          <span>{trailing()}</span>
        </div>
      </TabHeaderContext.Provider>
    </TabButton>
  );
});

// Make `value` optional
const TabHeader = withSlot<Omit<Option<string, boolean | null>, 'value'> & { value?: boolean | null }, SlotDefinitions<['Leading', 'Trailing']>>(
  () => {
    // Dummy component
    return null;
  },
);

type TabContentProps = {
  forId: string;
  onChange?: () => void;
};

const TabContent: FC<TabContentProps & { children: ReactNode | ReactElement | ((active: boolean) => ReactNode | ReactElement) }> = () => {
  // Dummy component
  return null;
};

const TabContentInternal: FC<
  TabContentProps & { active: boolean; children: ReactNode | ReactElement | ((active: boolean) => ReactNode | ReactElement) }
> = (props) => {
  return (
    <Suspense fallback={<PageLoader loading isSuspense />}>
      {typeof props.children === 'function' ? props.children(props.active) : props.children}
    </Suspense>
  );
};

type TabStripType<P = Record<string, unknown>> = FCWithChildren<P> & {
  TabHeader: typeof TabHeader;
  TabContent: typeof TabContent;
};

type TabStripProps = {
  evenTabs?: boolean;
  centered?: boolean;
  defaultTabId?: string;
  headerClassName?: string;
  contentClassName?: string;
  enableHash?: boolean;
  enableSticky?: boolean;
  wrapper?: FC;
  borderless?: boolean;
  fillSelected?: boolean;
  allowWrapping?: boolean;
  overflowHidden?: boolean;
  onChange?: (newTabId: string, newIndex: number) => void;
  tabBgColor?: string;
  position?: 'top' | 'bottom';
  overflowTabs?: boolean;
};

export const TabStrip: TabStripType<TabStripProps> = (props) => {
  const {
    children,
    evenTabs,
    defaultTabId,
    contentClassName,
    headerClassName,
    enableHash,
    enableSticky,
    wrapper: Wrapper = 'div',
    centered,
    borderless,
    allowWrapping,
    overflowHidden,
    onChange,
    tabBgColor,
    position = 'top',
    overflowTabs,
    fillSelected,
  } = props;
  const isTopNav = Wrapper === TopNavPortal;
  const { updateParentHeight: updateParentAcordion } = useAccordionContext();

  const childrenArr = Children.toArray(children);
  let tabHeaders: any[] = childrenArr.filter(({ type }: any) => type === TabHeader);
  let tabContents: any[] = childrenArr.filter(({ type }: any) => type === TabContent);
  const navigate = useNavigate();
  const location = useLocation();

  if (tabHeaders.length === 0 && tabContents.length === 0) {
    let newChildrenArr: any[] = [];
    for (const el of childrenArr) {
      newChildrenArr = newChildrenArr.concat(Children.toArray((el as any).props.children));
    }

    tabHeaders = newChildrenArr.filter(({ type }: any) => type === TabHeader);
    tabContents = newChildrenArr.filter(({ type }: any) => type === TabContent);
  }

  const [selectedTabId, setSelectedTabId] = useState(defaultTabId || tabHeaders[0]?.props.id);

  useEffect(() => {
    const selectedTab = tabHeaders.find((x) => x.props.id === selectedTabId);
    if (!selectedTab) {
      setSelectedTabId(tabHeaders[0]?.props.id);
    }
  }, [selectedTabId, tabHeaders]);

  useEffect(() => {
    updateParentAcordion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId]);

  const handleClick = useCallback(
    (tabId: string): void => {
      if (enableHash) {
        navigate({ hash: tabId }, { replace: true });
      }
      setSelectedTabId(tabId);
      const selectedContent = tabContents.find((el) => el.props.forId === tabId);
      selectedContent.props.onChange && selectedContent.props.onChange();
      onChange &&
        onChange(
          tabId,
          tabHeaders.findIndex((x) => x.props.id === tabId),
        );
    },
    [enableHash, navigate, onChange, tabContents, tabHeaders],
  );

  useEffect(() => {
    if (!enableHash) {
      return;
    }

    if (location.hash) {
      const selectedTab = tabHeaders.find((x) => x.props.id === location.hash.substr(1));
      setSelectedTabId(!selectedTab ? tabHeaders[0]?.props.id : location.hash.substr(1));
    } else {
      navigate({ hash: selectedTabId }, { replace: true });
    }
  }, [enableHash, location.hash, navigate, selectedTabId, tabHeaders]);

  const enableStickyHeader = (enableSticky?: boolean): string => {
    if (enableSticky) {
      return (overflowHidden ? 'overflow-hidden' : 'overflow-y-auto') + ' h-0';
    }
    return '';
  };

  const tabScrollContainerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const scrollButtonClasses =
    'flex items-center justify-center rounded-md text-gray-2 max-h-[30px] h-[30px] min-w-[30px] px-1 cursor-pointer hover:bg-gray-51w-9 h-8 py-1';

  const updateScrollButtons = () => {
    if (tabScrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabScrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollLeft = () => {
    if (tabScrollContainerRef.current) {
      tabScrollContainerRef.current.scrollBy({
        left: -100,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (tabScrollContainerRef.current) {
      tabScrollContainerRef.current.scrollBy({
        left: 100,
        behavior: 'smooth',
      });
    }
  };

  useLayoutEffect(() => {
    updateScrollButtons();
  }, []);

  useEffect(() => {
    const tabContainerElement = tabScrollContainerRef.current;
    tabContainerElement?.addEventListener('scroll', updateScrollButtons);
    window.addEventListener('resize', updateScrollButtons);

    return () => {
      tabContainerElement?.removeEventListener('scroll', updateScrollButtons);
      window.removeEventListener('resize', updateScrollButtons);
    };
  }, []);

  const tabs = useMemo(() => {
    return (
      <Wrapper>
        {overflowTabs && (
          <div className="fixed bottom-[10px] right-5 flex items-center space-x-2 bg-white">
            <ChevronIcon
              type={ChevronType.LEFT}
              className={`${scrollButtonClasses} ${!canScrollLeft ? 'pointer-events-none opacity-50' : ''}`}
              onClick={scrollLeft}
            />
            <ChevronIcon
              type={ChevronType.RIGHT}
              className={`${scrollButtonClasses} ${!canScrollRight ? 'pointer-events-none opacity-50' : ''}`}
              onClick={scrollRight}
            />
          </div>
        )}
        <div
          ref={tabScrollContainerRef}
          className={`flex ${headerClassName} ${centered && 'justify-center'} ${overflowTabs ? 'overflow-x-hidden' : ''} w-full ${allowWrapping ? 'flex-wrap px-1' : ''}`}
          data-testid="tab-strip-tabs"
          style={{ maxWidth: overflowTabs ? 'calc(100% - 180px)' : undefined }} // Adjust width to account for arrow buttons
        >
          {tabHeaders.map((tab) => (
            <TabHeaderInternal
              key={tab.props.id}
              {...tab.props}
              evenTabs={evenTabs}
              internalState={{ onClick: handleClick, selected: tab.props.id === selectedTabId }}
              borderless={borderless}
              allowWrapping={allowWrapping}
              bgColor={tabBgColor}
              overflowTabs={overflowTabs}
              isBottom={position === 'bottom'}
              fillSelected={fillSelected}
            />
          ))}
          {!evenTabs && !centered && !borderless && <div className={`flex-grow ${!isTopNav ? 'border-b-2' : ''}`}></div>}
        </div>
      </Wrapper>
    );
  }, [
    Wrapper,
    allowWrapping,
    borderless,
    canScrollLeft,
    canScrollRight,
    centered,
    evenTabs,
    fillSelected,
    handleClick,
    headerClassName,
    isTopNav,
    overflowTabs,
    position,
    selectedTabId,
    tabBgColor,
    tabHeaders,
  ]);

  return (
    <div className="flex h-full flex-col">
      {position === 'top' && tabs}
      {tabContents.map((content) => (
        <div
          {...dataAttributeProps(content.props)}
          key={`content-${content.props.forId}`}
          className={`${contentClassName} ${selectedTabId === content.props.forId ? 'flex-grow' : 'hidden'} ${enableStickyHeader(enableSticky)}`}
        >
          <TabContentInternal active={selectedTabId === content.props.forId} {...content.props} />
        </div>
      ))}
      {position === 'bottom' && tabs}
    </div>
  );
};

TabStrip.TabHeader = TabHeader;
TabStrip.TabContent = TabContent;
